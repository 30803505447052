import * as React from "react"
import Layout from "../components/layout/layout"
import { graphql, Link } from 'gatsby'
import { useTranslation } from "gatsby-plugin-react-i18next";
import SEOComponent from '../components/seo'
import TitleAltComponent from "../components/title-alt"

import "../styles/rte.css"
const SiteMapPage = ({ data, }) => {
  const { t } = useTranslation();
  const siteContent = data.siteContent
  const ressources = data.ressourcesArticles.nodes;
  const news = data.newsArticles.nodes;
  return (
    <main>
      <Layout siteContent={siteContent}>
        <SEOComponent title={t("roadmap_site")} />
        <TitleAltComponent title={t("roadmap_site")}></TitleAltComponent>
        <div className="container rte px-4 mx-auto text-lg leading-7">
          <ul>
            <li><Link to="/" aria-label={t("menu_home")}>{t("menu_home")}</Link></li>

            <li>
              <Link to="/edition" aria-label={t("menu_edition", { year: siteContent.editionYear })} >{t("menu_edition", { year: siteContent.editionYear })}</Link>
            </li>

            <li>
              <Link to="/ressources" aria-label={t("menu_ressources")} >{t("menu_ressources")}</Link>
              <ul>
                {ressources.map(article => <li><Link to={`/article/${article.slug}`} aria-label={article.title} >{article.title}</Link></li>)}
              </ul>
            </li>
            
            <li>
              <Link to="/commitment" aria-label={t("menu_news")} >{t("menu_news")}</Link>
              <ul>
                {news.map(article => <li><Link to={`/article/${article.slug}`} aria-label={article.title} >{article.title}</Link></li>)}
              </ul>
            </li>
            
            <li><Link to="/sustainable-week" aria-label={t("euro_responsible_digital_week")} >{t("euro_responsible_digital_week")}</Link></li>
            
            <li><Link to="/programmation" aria-label={t("programme")} >{t("programme")}</Link></li>
            <li><Link to="/programme" aria-label={t("programmation")} >{t("programmation")}</Link></li>
            <li><Link to="/partenaires" aria-label={t("menu_partners")} >{t("menu_partners")}</Link></li>

            <li><Link to="/search" aria-label={t("menu_search")} >{t("menu_search")}</Link></li>

            <li><Link to="/contact" aria-label={t("menu_hmenu_contactome")} >{t("menu_contact")}</Link></li>

            <li><a href={siteContent.personalDataURL} aria-label={t("personal_data")} >{t("personal_data")}</a></li>

            <li><a href={siteContent.cookieURL} aria-label={t("cookie_policy")} >{t("cookie_policy")}</a></li>

            <li><Link to="/accessibility" aria-label={t("accessibility")} >{t("accessibility")}</Link></li>

            <li><Link to="/sitemap" aria-label={t("roadmap_site")} >{t("roadmap_site")}</Link></li>
          </ul>
        </div>
      </Layout>
    </main>
  )
}
export default SiteMapPage;

export const query = graphql`
query($language: String!) {
  locales: allLocale(filter:{language: {eq: $language}}) {
    edges {
      node {
        ns
        data
        language
      }
    }
  }
  siteContent(language: {eq: $language}) {
    editionYear
    programYear
    language
    cookieURL
    mediaKit
    personalDataURL
    banner
  }
  ressourcesArticles: allArticle(
    sort: {fields: datePublished},
    filter: {
      typeArticle: {eq: ressources}, 
      language: {eq: $language}
    }
  ) {
    nodes {
      title
      slug
    }
  }
  newsArticles: allArticle(
    sort: {fields: datePublished},
    filter: {
      typeArticle: {eq: news}, 
      language: {eq: $language}
    }
  ) {
    nodes {
      title
      slug
    }
  }
}
`